import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"

import SectionInner from "../../components/section-inner"
import Section from "../../components/section"
import Pagination from "../../components/pagination"
import ProductCard from "../../components/product-card"
import Grid from "@material-ui/core/Grid"

import GridSearch from "../../components/grid-search"
import { getCardMedia } from "../../helper"
import _ceil from "lodash.ceil"
import ProductStepper from "../../components/product-stepper/product-stepper"
import { ORDER_ADDONS } from "../../stepper/orderTypes"
import { useDialog } from "../../hooks/useDialog"
import { setAddonOrders, setOrderType } from "../../state/actions"
import { connect } from "react-redux"
import FloatingAction from "../../components/floating-action"
import Zoom from "@material-ui/core/Zoom"
import { useTheme } from "@material-ui/core"
import FilterListIcon from "@material-ui/icons/FilterList"
import ActionButton from "../../components/action-button"
import AppsIcon from "@material-ui/icons/Apps"
import TableView from "../../components/table-view"
import htmlDecoder from "he"
import { useTranslation } from "react-i18next"

const AddonGrid = ({ addons, productsPerPage, addonOrders, setAddonOrders, showOrderFab, setOrderType, lang }) => {

  const theme = useTheme()
  const {t} = useTranslation()
  const { handleDialogOpen } = useDialog()

  const [showTableView, setShowTableView] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [localLang, setLocalLang] = useState(lang)

  const scrollGridIntoView = () => {
    let gridEl = document.getElementById("AddonGrid")
    if(gridEl) gridEl.scrollIntoView()

  };


  const pageCount = _ceil(addonOrders.length / productsPerPage)

  const preparedAddons = useMemo(() => addons.map(addon => {
    return {
      id: addon.addOnId,
      count: 0,
      title: htmlDecoder.decode(addon.title),
      type: addon.termNames ? addon.termNames[0] : null,
      featuredImage: addon.featuredImage,
      description: addon.product.description,
      bottomInfo: addon.product.bottomInfo,
      downloadLink: addon.product.downloadLink,
      pdf: addon.product.pdf,
      showOnlyPdf: addon.product.showOnlyPdf,
      subtitle: htmlDecoder.decode(addon.product.subtitle),
      price: addon.product.price ? addon.product.price : 0,
    }
  }), [addons])

  const [state, setState] = useState({
    offset: 0,
    pageCount: pageCount,
    pageProducts: [...preparedAddons.slice(0, productsPerPage)],
    activePage: 1,
    searchIsActive: false,
  })

  useEffect(() => {
    if (addonOrders && (preparedAddons.length !== addonOrders.length)) {
      setAddonOrders(preparedAddons)
    } else if (localLang !== lang) {
      setLocalLang(lang)
      setAddonOrders(preparedAddons)
    }
  }, [setAddonOrders, preparedAddons, addonOrders, localLang, lang])


  useEffect(() => {
    setState(prevState => {
      return {
        pageCount: pageCount,
        searchIsActive: prevState.searchIsActive,
        offset: prevState.offset,
        activePage: prevState.activePage,
        pageProducts: addonOrders.slice(prevState.offset, prevState.offset + productsPerPage),
      }
    })
  }, [addonOrders, setState, pageCount, productsPerPage, localLang])


  // productsPerPage = parseInt(productsPerPage)
  // console.log("productsPerPage", productsPerPage)
  // console.log("addons.length", addons.length)
  // console.log("page Count",  _ceil(addons.length / productsPerPage))


  const handlePageChange = (selectedPage) => {
    // console.log("selectedPage", selectedPage)

    let offset = ((selectedPage - 1) * productsPerPage)

    // console.log("offset", offset)
    // console.log("slice from " + offset + " to " + (offset + productsPerPage))
    //
    // console.log("Products on page:", addons.slice(offset, offset + productsPerPage))

    setState(prevState => {
      return {
        pageCount: pageCount,
        searchIsActive: false,
        offset: offset,
        activePage: selectedPage,
        pageProducts: addonOrders.slice(offset, offset + productsPerPage),
      }
    })
  }
  const handleSearch = (e, searchValue) => {
    e.preventDefault()
    setSearchValue(searchValue)
    const lowercasedSearch = searchValue.toLowerCase()

    setState(prevState => {
      return {
        ...prevState,
        searchIsActive: searchValue !== "",
        pageProducts: searchValue !== "" ? addonOrders.filter((product) => {
          const flattenedProduct = {
            title: product.title,
            subtitle: product.subtitle,
            description: product.description,
            type: product.type,
          }

          return Object.keys(flattenedProduct).some(key => {
              return flattenedProduct[key] ? flattenedProduct[key].toString().toLowerCase().includes(lowercasedSearch) : false
            },
          )
        }) : addonOrders.slice(state.offset, state.offset + productsPerPage),
      }
    })
  }


  const createPdf = (event) => {
    event.preventDefault()
    setOrderType(ORDER_ADDONS)

    handleDialogOpen(
      t("Bestellung") + ": TRNSYS 18 Add-Ons",
      <ProductStepper/>,
      false)
  }

  // console.log(addons)
  //
  // console.log(state.pageProducts)
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  }


  return (
    <Section id="AddonGrid" className="ProductGrid Section--vPadding">
      {
        <Zoom
          in={showOrderFab !== 0}
          timeout={transitionDuration}
          mountOnEnter
          unmountOnExit
        >
          <FloatingAction action={createPdf}/>
        </Zoom>
      }
      <SectionInner>

        <div className={"u-textRight"}>
          <ActionButton className={"t-iconLink u-noShadow"} onClick={() => setShowTableView(!showTableView)}>
            <span className={"u-padding-sm t-textLink"}>{showTableView ? t("Zeige Gridansicht") : t("Zeige Tabellenansicht") }</span>
            {
              showTableView ?
                <AppsIcon color={"secondary"}/>
                :
                <FilterListIcon color={"secondary"}/>
            }
          </ActionButton>
        </div>

        {
          <GridSearch className="ProductGrid-search" searchValue={searchValue} handleSearch={handleSearch}/>
        }
        <div className="ProductGrid-products CardGrid">
          {
            !showTableView &&
            <>
              <Grid container justify="flex-start" spacing={5} style={{ position: "relative", marginBottom: "40px" }}>
                {
                  state.pageProducts.map((addonItem, index) => {
                      return (
                        <Grid key={addonItem.id ? addonItem.id : index} item xs={12} lg={6} xl={4}>
                          <ProductCard index={addonItem.id ? addonItem.id : index} {...addonItem}
                                       cardMedia={getCardMedia(addonItem.featuredImage)} isAddonGrid={true}/>
                        </Grid>
                      )
                    },
                  )
                }
              </Grid>
              {
                !state.searchIsActive &&
                <Pagination
                  pageCount={state.pageCount}
                  pageRangeDisplayed={state.pageCount}
                  onPageChange={handlePageChange}
                  activePage={state.activePage}
                  scrollGridIntoView={scrollGridIntoView}
                />
              }
            </>
          }
          {
            showTableView &&
            <TableView addons={!state.searchIsActive ? addonOrders : state.pageProducts}/>
          }


        </div>
      </SectionInner>
    </Section>
  )
}

AddonGrid.propTypes = {
  addons: PropTypes.array,
  productsPerPage: PropTypes.number,
}

AddonGrid.defaultProps = {
  productsPerPage: 9,
}


const mapStateToProps = (state) => {
  return {
    addonOrders: state.addonOrders,
    showOrderFab: state.showOrderFab,
    lang: state.lang,
  }
}

const mapDispatchToProps = {
  setAddonOrders,
  setOrderType
}


export default connect(mapStateToProps, mapDispatchToProps)(AddonGrid)
