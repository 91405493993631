import React from "react"
import PropTypes from "prop-types"

import "./action-button.scss"
import Button from "@material-ui/core/Button"

const ActionButton = ({ title, children, className, variant, ...props}) => {
  return (
    <Button color={"primary"} size={"large"} variant={variant} title={title} {...props}>
      {children}
    </Button>
  )
}


ActionButton.propTypes = {
  outlined: PropTypes.bool,
}


export default ActionButton

