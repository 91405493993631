import { useTranslation } from "react-i18next"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import React from "react"

const ProductStepperFormActions = ({ activeStep, handleBack, stepCount }) => {
  const {t} = useTranslation()

  return <>
    <Divider className={"ProductStepper-dividerTopMargin"}/>

    <div className={"ProductStepper-actions"}>

      <Button
        disabled={activeStep === 0}
        className={"ProductStepper-actionButton"}
        onClick={handleBack}
      >
        {t("Zurück")}
      </Button>
      <Button type={"submit"} variant="contained" color="primary" className={"ProductStepper-actionButton"}>
        {activeStep === stepCount - 1 ? t("Abschliessen") : t("Weiter")}
      </Button>
    </div>
  </>
}

export default ProductStepperFormActions
