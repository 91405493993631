import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import ProductStepperFormActions from "../components/product-stepper-form-actions"
import useFormFields from "../hooks/useFormFields"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { setAdvancedSupport, setSupportEmails, setSupportInfo } from "../state/actions"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import initialState from "../state/initialState"
import { ORDER_SUPPORT } from "./orderTypes"


const SupportStep = ({ activeStep, stepCount, setActiveStep, supportInfo, setSupportInfo, setSupportEmails, setAdvancedSupport, orderType }) => {

  const { t } = useTranslation()

  const isSupport = orderType === ORDER_SUPPORT

  const [supportInfoFields, handleSupportInfoFieldChange, resetSupportInfoFields] = useFormFields({
    ...supportInfo,
    advancedSupport: isSupport ? true : supportInfo.advancedSupport
  })

  const handleAdvancedSupport = (e) => {
    setAdvancedSupport(e.target.checked)
    if (e.target.checked) {
      resetSupportInfoFields({
        ...supportInfo,
        advancedSupport: true
      })
    } else {
      resetSupportInfoFields({
        ...initialState.supportInfo,
        advancedSupport: false
      })
    }
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    if (supportInfo.advancedSupport || isSupport) {
      setSupportInfo(supportInfoFields)
    }
  }

  const handleNext = (event) => {
    event.preventDefault()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    if (supportInfo.advancedSupport || isSupport) {
      setSupportInfo(supportInfoFields)
    }
  }

  // const handleChange = (e) => {
  //   setUserId(e.target.value)
  // }

  return <form action={"post"} onSubmit={handleNext}>

    <Grid className={"ProductStepper-step"} container spacing={3}>
      <Grid item xs={12}>

        {
          isSupport ? <Typography variant={"body1"}>{t("supportText")}</Typography>
            :
          <FormControlLabel
            control={
              <Checkbox
                checked={supportInfo.advancedSupport}
                onChange={handleAdvancedSupport}
                name={"advancedSupport"}
                value={"primary"}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={<Typography variant={"body2"}>{t("addedSupportText")}</Typography>}
          >
          </FormControlLabel>
        }


      </Grid>
      <Grid item xs={12}>
        <TextField
          id="supportInfo-email1"
          className={"ProductStepper-field"}
          label={"E-Mail"}
          name={"email1"}
          value={supportInfoFields.email1}
          onChange={handleSupportInfoFieldChange}
          variant="outlined"
          disabled={isSupport ? false : !supportInfo.advancedSupport}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="supportInfo-email2"
          className={"ProductStepper-field"}
          label={"E-Mail"}
          name={"email2"}
          value={supportInfoFields.email2}
          onChange={handleSupportInfoFieldChange}
          disabled={isSupport ? false : !supportInfo.advancedSupport}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="supportInfo-email3"
          className={"ProductStepper-field"}
          label={"E-Mail"}
          name={"email3"}
          value={supportInfoFields.email3}
          onChange={handleSupportInfoFieldChange}
          disabled={isSupport ? false : !supportInfo.advancedSupport}
          variant="outlined"
        />
      </Grid>
    </Grid>

    <ProductStepperFormActions activeStep={activeStep} handleBack={handleBack} stepCount={stepCount}/>

  </form>
}

const mapStateToProps = (state) => {
  return {
    supportInfo: state.supportInfo,
    orderType: state.orderInfo.orderType
  }
}

const mapDispatchToProps = {
  setSupportEmails,
  setAdvancedSupport,
  setSupportInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(SupportStep)
