import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { setIsUpdate, setUserId } from "../state/actions"
import ProductStepperFormActions from "../components/product-stepper-form-actions"
import useFormFields from "../hooks/useFormFields"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"

const OrderInfoStep = ({ activeStep, stepCount, setActiveStep, userId, isUpdate, setUserId, setIsUpdate }) => {

  const { t } = useTranslation()
  const [userInfoFields, handleUserInfoFieldChange] = useFormFields({
    userId: userId,
    isUpdate: isUpdate,
  })

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setUserId(userInfoFields.userId)
    setIsUpdate(userInfoFields.isUpdate)
  }

  const handleNext = (event) => {
    event.preventDefault()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setUserId(userInfoFields.userId)
    setIsUpdate(userInfoFields.isUpdate)
  }

  // const handleChange = (e) => {
  //   setUserId(e.target.value)
  // }


  return <form action={"post"} onSubmit={handleNext}>

    <Grid className={"ProductStepper-step"} container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id="userInfo-userId"
          className={"ProductStepper-field"}
          label={t("userIdLabel")}
          name={"userId"}
          value={userInfoFields.userId}
          onChange={handleUserInfoFieldChange}
          variant="outlined"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              checked={userInfoFields.isUpdate}
              onChange={handleUserInfoFieldChange}
              name="isUpdate"
              color="primary"
            />
          }
          label={t("Update")}
        />
        <Typography className={"t-body2"}>{t("orderInfoUpdateText")}</Typography>


      </Grid>

    </Grid>

    <ProductStepperFormActions activeStep={activeStep} handleBack={handleBack} stepCount={stepCount}/>

  </form>
}

const mapStateToProps = (state) => {
  return {
    userId: state.orderInfo.userId,
    isUpdate: state.orderInfo.isUpdate,
  }
}

const mapDispatchToProps = {
  setUserId,
  setIsUpdate,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfoStep)
