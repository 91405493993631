import React, { useEffect, useState } from "react"
import Fab from "@material-ui/core/Fab"
import PdfIcon from "../../assets/svg/icons/pdf.svg"
import "./floating-action.scss"
import Tooltip from "@material-ui/core/Tooltip"
import { connect } from "react-redux"
import { setShowOrderFab } from "../../state/actions"
import { useTranslation } from "react-i18next"

const FloatingAction = ({ action, showOrderFab, setShowOrderFab }) => {
  const [showTooltipLocal, setShowTooltipLocal] = useState(false)
  const {t} = useTranslation()

  useEffect(() => {

    if (showOrderFab === 1) {
      setShowTooltipLocal(true)

      setTimeout(() => {
        setShowTooltipLocal(false)
        setShowOrderFab(2)
      }, 2000)
    }

  }, [showOrderFab, setShowOrderFab])


  return (
      <Tooltip className={"FloatingAction-tooltip"} title={t("Bestellformular erstellen")} placement={"left"}
               open={showTooltipLocal}>
        <Fab className={"FloatingAction"} color="primary" variant={"round"} size={"large"}
             aria-label="Print order form" onClick={action} onMouseEnter={() => setShowTooltipLocal(true)} onMouseLeave={() => setShowTooltipLocal(false)}>
          <PdfIcon className={"FloatingAction-icon"}/>
        </Fab>
      </Tooltip>
  )
}

const mapStateToProps = (state) => {
  return {
    showOrderFab: state.showOrderFab,
  }
}

const mapDispatchToProps = {
  setShowOrderFab,
}


export default connect(mapStateToProps, mapDispatchToProps)(FloatingAction)
