import React, { useEffect } from "react"
import TextField from "@material-ui/core/TextField"
import useFormFields from "../hooks/useFormFields"
import { setBillingAddress, setBillingIsSame, setUst } from "../state/actions"
import { connect } from "react-redux"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import initialState from "../state/initialState"
import ProductStepperFormActions from "../components/product-stepper-form-actions"
import { useTranslation } from "react-i18next"
import Divider from "@material-ui/core/Divider"

const BillingAddressStep = ({ billingAddress, activeStep, setActiveStep, stepCount, setBillingAddress, shippingAddress, billingIsSame, setBillingIsSame, ust, setUst }) => {
  const { t } = useTranslation()

  const [billingFields, handleBillingFieldChange, resetBillingFields] = useFormFields({
    ...billingAddress,
  })

  useEffect(() => {
    if (billingIsSame) {
      setBillingAddress(shippingAddress)
      resetBillingFields(shippingAddress)
    }

  }, [billingIsSame, resetBillingFields, setBillingAddress, shippingAddress])


  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setBillingAddress(billingFields)
  }

  const handleNext = (event) => {
    event.preventDefault()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setBillingAddress(billingFields)
  }

  const handleUstFieldChange = (e) => {
    setUst(e.target.value)
  }

  const handleIsSameCheck = (e) => {
    setBillingIsSame(e.target.checked)

    if (!e.target.checked) {
      setBillingAddress(initialState.billingAddress)
      resetBillingFields(initialState.billingAddress)
    } else {
      setBillingAddress(shippingAddress)
      resetBillingFields(shippingAddress)
    }
  }

  return (
    <form action={"post"} onSubmit={handleNext}>
      <Grid className={"ProductStepper-step"} container spacing={3}>
        <Grid item xs={12}>
          <TextField
            id="billing-ust"
            className={"ProductStepper-field"}
            label="USt-IdNr (VAT Reg No)"
            name={"ust"}
            value={ust}
            onChange={handleUstFieldChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={billingIsSame}
                onChange={handleIsSameCheck}
                name={"isSame"}
                value={"primary"}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            }
            label={<Typography variant={"body2"}>{t("addressSameText")}</Typography>}
          >
          </FormControlLabel>

        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-email"
            className={"ProductStepper-field"}
            label="E-Mail"
            name={"email"}
            value={billingFields.email}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-phone"
            className={"ProductStepper-field"}
            label={t("Telefon")}
            name={"phone"}
            value={billingFields.phone}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>

        <Divider className={"u-divider"} />

        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-firstName"
            className={"ProductStepper-field"}
            label={t("Vorname")}
            name={"firstName"}
            value={billingFields.firstName}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-lastName"
            className={"ProductStepper-field"}
            label={t("Nachname")}
            name={"lastName"}
            value={billingFields.lastName}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-organization"
            className={"ProductStepper-field"}
            label={t("Firma / Hochschule")}
            name={"organization"}
            value={billingFields.organization}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-department"
            className={"ProductStepper-field"}
            label={t("Abteilung / Institut")}
            name={"department"}
            value={billingFields.department}
            onChange={handleBillingFieldChange}
            variant="outlined"
          />
        </Grid>

        <Divider className={"u-divider"} />

        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-street"
            className={"ProductStepper-field"}
            label={t("Straße und Hausnummer")}
            name={"street"}
            value={billingFields.street}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-postalCode"
            className={"ProductStepper-field"}
            label={t("Postleitzahl")}
            name={"postalCode"}
            value={billingFields.postalCode}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-city"
            className={"ProductStepper-field"}
            label={t("Stadt")}
            name={"city"}
            value={billingFields.city}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            disabled={billingIsSame}
            id="billing-country"
            className={"ProductStepper-field"}
            label={t("Land")}
            name={"country"}
            value={billingFields.country}
            onChange={handleBillingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>
      <ProductStepperFormActions activeStep={activeStep} handleBack={handleBack} stepCount={stepCount}/>

    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    billingAddress: state.billingAddress,
    billingIsSame: state.billingIsSame,
    shippingAddress: state.shippingAddress,
    ust: state.orderInfo.ust
  }
}

const mapDispatchToProps = {
  setBillingAddress,
  setBillingIsSame,
  setUst,
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingAddressStep)
