import React from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { setIsEducationalAddons, setIsUpdateAddons, setUserId } from "../state/actions"
import ProductStepperFormActions from "../components/product-stepper-form-actions"
import useFormFields from "../hooks/useFormFields"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"

const OrderInfoAddonsStep = ({ activeStep, stepCount, setActiveStep, userId, isUpdateAddons, isEducationalAddons, setUserId, setIsUpdateAddons, setIsEducationalAddons, isAddonOrder }) => {

  const { t } = useTranslation()
  const [userInfoFields, handleUserInfoFieldChange] = useFormFields({
    userId: userId,
    isUpdateAddons: isUpdateAddons,
    isEducationalAddons: isEducationalAddons,
  })

  const setAllValues = () => {
    setUserId(userInfoFields.userId)
    setIsUpdateAddons(!!userInfoFields.isUpdateAddons)
    setIsEducationalAddons(!!userInfoFields.isEducationalAddons)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setAllValues()
  }

  const handleNext = (event) => {
    event.preventDefault()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setAllValues()
  }

  // const handleChange = (e) => {
  //   setUserId(e.target.value)
  // }


  return <form action={"post"} onSubmit={handleNext}>

    <Grid className={"ProductStepper-step"} container spacing={3}>

      <Grid item xs={12}>

        <TextField
          id="userInfo-userId"
          className={"ProductStepper-field u-topMarginSm"}
          label={t("userIdLabelAddons")}
          name={"userId"}
          value={userInfoFields.userId}
          onChange={handleUserInfoFieldChange}
          variant="outlined"
          required
        />
      </Grid>
      {
        isAddonOrder &&
        <>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!userInfoFields.isUpdateAddons}
                  onChange={handleUserInfoFieldChange}
                  name="isUpdateAddons"
                  color="primary"
                />
              }
              label={"Update"}
            />
            <Typography className={"t-body2"}>{t("orderInfoUpdateAddonsText")}</Typography>

          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!userInfoFields.isEducationalAddons}
                  onChange={handleUserInfoFieldChange}
                  name="isEducationalAddons"
                  color="primary"
                />
              }
              label={t("Hochschullizenz")}
            />
          </Grid>
        </>
      }

    </Grid>

    <ProductStepperFormActions activeStep={activeStep} handleBack={handleBack} stepCount={stepCount}/>

  </form>
}

const mapStateToProps = (state) => {
  return {
    userId: state.orderInfo.userId,
    isUpdateAddons: state.orderInfo.isUpdateAddons,
    isEducationalAddons: state.orderInfo.isEducationalAddons,
  }
}

const mapDispatchToProps = {
  setUserId,
  setIsUpdateAddons,
  setIsEducationalAddons,
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfoAddonsStep)
