import { useState } from "react";

const useFormFields = (initialState) => {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      const value = event.target.checked ? event.target.checked : event.target.value
      setValues({
        ...fields,
        [event.target.name]: value
      });
    },
    function(resetValue) {
      setValues(
        resetValue
      );
    }
  ];
}

export default useFormFields
