import React from "react"
import { Box, Stepper, useMediaQuery, useTheme } from "@material-ui/core"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "@material-ui/core/Button"

import "./product-stepper.scss"
import ShippingAddressStep from "../../stepper/ShippingAddressStep"
import BillingAddressStep from "../../stepper/BillingAddressStep"
import LicenseTypeStep from "../../stepper/LicenseTypeStep"
import PrintOrderStep from "../../stepper/PrintOrderStep"
import { connect } from "react-redux"
import { resetOrder } from "../../state/actions"
import { useDialog } from "../../hooks/useDialog"
import Divider from "@material-ui/core/Divider"
import { useTranslation } from "react-i18next"
import OrderInfoStep from "../../stepper/OrderInfoStep"
import * as orderTypes from "../../stepper/orderTypes"
import { ORDER_ADDONS } from "../../stepper/orderTypes"
import SupportStep from "../../stepper/SupportStep"
import Typography from "@material-ui/core/Typography"
import OrderInfoAddonsStep from "../../stepper/OrderInfoAddonsStep"


const getSteps = (t, orderType) => {
  const allSteps = [
    t("Bestellinfos"), // 0
    t("Lizenz Typ"), // 1
    t("Support"), // 2
    t("Lieferanschrift"), // 3
    t("Rechnungsanschrift"), // 4
    t("Bestellformular drucken") // 5
  ]

  switch (orderType) {
    case orderTypes.ORDER_TRNSYS_FIRST:
      return [allSteps[1], allSteps[2], allSteps[3], allSteps[4], allSteps[5]]
    case orderTypes.ORDER_TRNSYS_UPDATE:
      return [allSteps[0], allSteps[1], allSteps[2], allSteps[3], allSteps[4], allSteps[5]]
    case orderTypes.ORDER_ADDONS:
      return [allSteps[0], allSteps[3], allSteps[4], allSteps[5]]
    case orderTypes.ORDER_SUPPORT:
      return [allSteps[0], allSteps[2], allSteps[3], allSteps[4], allSteps[5]]
    default:
      return []
  }
}

const stepMapping = {
  ORDER_TRNSYS_FIRST: [1, 2, 3, 4, 5],
  ORDER_TRNSYS_UPDATE: [0, 1, 2, 3, 4, 5],
  ORDER_ADDONS: [6, 3, 4, 5],
  ORDER_SUPPORT: [6, 2, 3, 4, 5],
}

const getStepContent = ({ orderType, ...props }) => {
  const allSteps = [
    <OrderInfoStep {...props}/>, // 0
    <LicenseTypeStep {...props}/>, // 1
    <SupportStep {...props}/>, // 2
    <ShippingAddressStep  {...props} />, // 3
    <BillingAddressStep  {...props} />, // 4
    <PrintOrderStep {...props} />, // 5
    <OrderInfoAddonsStep {...props}/>, // 6
  ]

  switch (orderType) {
    case orderTypes.ORDER_TRNSYS_FIRST:
      return allSteps[stepMapping.ORDER_TRNSYS_FIRST[props.activeStep]]
    case orderTypes.ORDER_TRNSYS_UPDATE:
      return allSteps[stepMapping.ORDER_TRNSYS_UPDATE[props.activeStep]]
    case orderTypes.ORDER_ADDONS:
      return allSteps[stepMapping.ORDER_ADDONS[props.activeStep]]
    case orderTypes.ORDER_SUPPORT:
      return allSteps[stepMapping.ORDER_SUPPORT[props.activeStep]]
    default:
      return "Unknown stepIndex"
  }
}

const ProductStepper = ({ resetOrder, orderType }) => {
  const { t } = useTranslation()

  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps(t, orderType)
  const { handleDialogClose } = useDialog()

  const handleReset = () => {
    resetOrder()
    handleDialogClose()
    // setActiveStep(0)
  }

  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up("lg"))


  const productStepContent = () => {
    return <Box className={"ProductStepper-content"}>
      {activeStep === steps.length ? (
        <div>
          <Typography>Erstellung des Bestellformulars abgeschlossen.</Typography>
          <Divider className={"ProductStepper-dividerTopMargin"}/>

          <div className={"ProductStepper-actions"}>
            <Button onClick={handleReset}>Reset</Button>
            <Button onClick={handleDialogClose}>Schließen</Button>
          </div>

        </div>
      ) : getStepContent({ activeStep, stepCount: steps.length, orderType, setActiveStep, isAddonOrder: orderType === ORDER_ADDONS })}
    </Box>
  }

  return (
    <>
      <Stepper activeStep={activeStep} orientation={desktop ? "horizontal" : "vertical"} alternativeLabel={desktop}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            {/*{!desktop && productStepContent()}*/}
          </Step>
        ))}

      </Stepper>
      {/*{*/}
      {/*  desktop &&*/}
      {/*  <>*/}
          <Divider className={"ProductStepper-dividerWMargin"}/>
          {productStepContent()}
      {/*  </>*/}
      {/*}*/}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    orderType: state.orderInfo.orderType,
  }
}

const mapDispatchToProps = {
  resetOrder,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductStepper)
