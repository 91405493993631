import React from "react"
import { useTranslation } from "react-i18next"
import FormControl from "@material-ui/core/FormControl"
import { connect } from "react-redux"
import { setLicenseType } from "../state/actions"
import Typography from "@material-ui/core/Typography"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import RadioGroup from "@material-ui/core/RadioGroup"
import Radio from "@material-ui/core/Radio"
import ProductStepperFormActions from "../components/product-stepper-form-actions"


const LicenseTypeStep = ({ activeStep, stepCount, setActiveStep, licenseType, setLicenseType }) => {

  const { t } = useTranslation()

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setLicenseType(licenseType)
  }

  const handleNext = (event) => {
    event.preventDefault()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setLicenseType(licenseType)
  }

  const handleChange = (e) => {
    setLicenseType(e.target.value)
  }

  return <form action={"post"} onSubmit={handleNext}>

  <FormControl component="fieldset">
      <RadioGroup aria-label="license" name="license" value={licenseType} onChange={handleChange}>
        <FormControlLabel labelPlacement={"end"} value="Node-lock" control={<Radio />} label={
            <Typography variant={"h6"} component={"h3"}>Node-lock</Typography>
        } />
        <Typography>{t("trnsysInfoPageText4")}</Typography>

        <FormControlLabel className={"u-topMarginSm"} value={t("Floating Lizenz")} control={<Radio />} label={
          <Typography variant={"h6"} component={"h3"}>{t("Floating Lizenz")}</Typography>
        }/>
        <Typography>{t('trnsysInfoPageText5')}</Typography>


      </RadioGroup>
    </FormControl>

    <ProductStepperFormActions activeStep={activeStep} handleBack={handleBack} stepCount={stepCount}/>

  </form>
}

const mapStateToProps = (state) => {
  return {
    licenseType: state.orderInfo.licenseType,
  }
}

const mapDispatchToProps = {
  setLicenseType,
}

export default connect(mapStateToProps, mapDispatchToProps)(LicenseTypeStep)
