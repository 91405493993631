import React from "react"
import TextField from "@material-ui/core/TextField"
import useFormFields from "../hooks/useFormFields"
import { setShippingAddress } from "../state/actions"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import ProductStepperFormActions from "../components/product-stepper-form-actions"
import { useTranslation } from "react-i18next"
import Divider from "@material-ui/core/Divider"

const ShippingAddressStep = ({ shippingAddress, activeStep, setActiveStep, stepCount, setShippingAddress }) => {
  const {t} = useTranslation()

  const [shippingFields, handleShippingFieldChange] = useFormFields({
    ...shippingAddress,
  })

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setShippingAddress(shippingFields)
  }

  const handleNext = (event) => {
    event.preventDefault()
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setShippingAddress(shippingFields)
  }

  return (
    <form action={"post"} onSubmit={handleNext}>
      <Grid className={"ProductStepper-step"} container spacing={3}>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-email"
            className={"ProductStepper-field"}
            label="E-Mail"
            name={"email"}
            value={shippingFields.email}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-phone"
            className={"ProductStepper-field"}
            label={t("Telefon")}
            name={"phone"}
            value={shippingFields.phone}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>

        <Divider className={"u-divider"} />

        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-firstName"
            className={"ProductStepper-field"}
            label={t("Vorname")}
            name={"firstName"}
            value={shippingFields.firstName}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-lastName"
            className={"ProductStepper-field"}
            label={t("Nachname")}
            name={"lastName"}
            value={shippingFields.lastName}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-organization"
            className={"ProductStepper-field"}
            label={t("Firma / Hochschule")}
            name={"organization"}
            value={shippingFields.organization}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-department"
            className={"ProductStepper-field"}
            label={t("Abteilung / Institut")}
            name={"department"}
            value={shippingFields.department}
            onChange={handleShippingFieldChange}
            variant="outlined"
          />
        </Grid>

        <Divider className={"u-divider"} />

        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-street"
            className={"ProductStepper-field"}
            label={t("Straße und Hausnummer")}
            name={"street"}
            value={shippingFields.street}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-postalCode"
            className={"ProductStepper-field"}
            label={t("Postleitzahl")}
            name={"postalCode"}
            value={shippingFields.postalCode}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-city"
            className={"ProductStepper-field"}
            label={t("Stadt")}
            name={"city"}
            value={shippingFields.city}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="shipping-country"
            className={"ProductStepper-field"}
            label={t("Land")}
            name={"country"}
            value={shippingFields.country}
            onChange={handleShippingFieldChange}
            variant="outlined"
            required
          />
        </Grid>
      </Grid>
      <ProductStepperFormActions activeStep={activeStep} handleBack={handleBack} stepCount={stepCount}/>
    </form>
  )
}

const mapStateToProps = (state) => {
  return {
    shippingAddress: state.shippingAddress,
  }
}

const mapDispatchToProps = {
  setShippingAddress,
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressStep)
