import React from "react"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import { makeStyles } from "@material-ui/core/styles"
import { getCardMedia } from "../../helper"
import { setShowOrderFab, updateAddonCount } from "../../state/actions"
import { connect } from "react-redux"
import { GetApp } from "@material-ui/icons"
import { Mail } from "@material-ui/icons"
import Fab from "@material-ui/core/Fab"
import { useTranslation } from "react-i18next"
import { createMailTo } from "../comp-utils"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  typeCell: {
    maxWidth: 80,
  },
}))

const TableView = ({ addons, updateAddonCount, showOrderFab, setShowOrderFab, lang }) => {

  const classes = useStyles()
  const { t } = useTranslation()

  const handleRowClick = (e, row) => {
    if (row.type !== "Free" && row.type !== "Contact Sales") {
      e.preventDefault()

      setShowOrderFab(1)

      updateAddonCount(row.id, row.count === 1 ? 0 : 1)
    }

  }

  return (
    <TableContainer component={Paper}>
      <Table size={"medium"}>
        <TableHead>
          <TableRow>
            <TableCell/>
            <TableCell className={classes.typeCell}>Image</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Type</TableCell>
            <TableCell align="right">Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addons.map(row => {
              return (
                <TableRow key={row.id}
                          role={"checkbox"}
                          onClick={(e) => handleRowClick(e, row)}
                          hover
                >
                  <TableCell padding="checkbox" style={{color: "#FFCA28"}}>
                    {
                      row.type === "Free" ? <Fab color={"inherit"} className={"u-noShadow u-marginLeft2"} href={row.downloadLink} target={"_blank"} rel={"noopener noreferer"}
                                                 size={"small"}><GetApp/></Fab> : (
                        row.type === "Contact Sales" ?
                          <Fab color={"inherit"} className={"u-noShadow u-marginLeft2"} href={createMailTo({title: row.title, t, lang})}
                               size={"small"}><Mail/></Fab>
                          :
                          <Checkbox
                            checked={row.count === 1}
                            inputProps={{ "aria-labelledby": row.id }}
                          />
                      )

                    }

                  </TableCell>
                  <TableCell className={classes.typeCell}>{getCardMedia(row.featuredImage)}</TableCell>
                  <TableCell component="th" scope="row" id={row.id} dangerouslySetInnerHTML={{ __html: row.title }}/>
                  <TableCell>{row.subtitle}</TableCell>
                  <TableCell className={"u-noWrap"}
                             align="right">{row.type === "Free" ? "Free" : (row.type === "Contact Sales" ? t("ANFRAGE") : row.price + " €")}</TableCell>
                </TableRow>
              )
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    showOrderFab: state.showOrderFab,
    lang: state.lang
  }
}

const mapDispatchToProps = {
  setShowOrderFab,
  updateAddonCount,
}

export default connect(mapStateToProps, mapDispatchToProps)(TableView)
