import PropTypes from "prop-types"
import React from "react"

import "./HeroVariantTwo.scss"

import Section from "../../components/section"
import SectionInner from "../../components/section-inner/section-inner"
import IconLink from "../../components/icon-link"
import SectionOuter from "../../components/section-outer"
import ArrowRight from "../../assets/svg/icons/arrow-right.svg"
import GatsbyImage from "gatsby-image"
import classNames from "classnames"

const HeroVariantTwo = ({ title, icon, description, links, backgroundImage, bgIsCenter, useImageAspectRatio }) => {

  const bgPosition = bgIsCenter ? "Hero-image--center" : "Hero-image--right"

  const getHeroAspect = (useImageAspectRatio && backgroundImage) ? {
    paddingBottom: (backgroundImage.mediaDetails.height / 2000 * 100) + "%",
    overflow: "hidden",
  } : { paddingBottom: "0" }

  // const getImageAspect = (useImageAspectRatio && backgroundImage) ? {
  //   paddingBottom: (backgroundImage.mediaDetails.height / backgroundImage.mediaDetails.width * 100) + "%",
  //   overflow: "hidden",
  // } : { paddingBottom: "0" }

  return (
    <Section className="Hero u-aspect-base" style={getHeroAspect}>

      <SectionOuter className="Hero-bgImage u-aspect-content">
        {
          backgroundImage &&
          (
            (backgroundImage.imageFile && backgroundImage.imageFile.extension !== "svg") ?
              <GatsbyImage fluid={backgroundImage.imageFile.childImageSharp.fluid} alt={backgroundImage.altText}/>
              : <img className={classNames("Hero-image", bgPosition)}
                     src={backgroundImage.imageFile ? backgroundImage.imageFile.publicURL : backgroundImage.sourceUrl}
                     alt={backgroundImage.altText}/>
          )
        }

      </SectionOuter>
      <SectionInner className="Hero-inner">
        <div className="Hero-info">
          <div className="Hero-titleWrapper">
            <h2 className="Hero-title t-h1">
              {title}
            </h2>
            {
              icon &&
              <img className="Hero-icon" src={icon.imageFile ? icon.imageFile.publicURL : icon.sourceUrl}
                   alt={icon.altText}/>
            }

          </div>
          <div className="Hero-description t-sectionBodyBig u-indent-left-small"
               dangerouslySetInnerHTML={{ __html: description }}>

          </div>
          {
            links &&
            links.map(({ link }, index) => {
              if (!link) return null

              return <IconLink key={index}
                               className="u-indent-left-small"
                               to={link.relativeUrl} title={link.title}
                               target={link.target} icon={ArrowRight}>
                {link.title}
              </IconLink>
            })
          }
        </div>
      </SectionInner>

    </Section>
  )
}

HeroVariantTwo.propTypes = {
  title: PropTypes.string,
  iconUrl: PropTypes.string,
  description: PropTypes.string,
  readMoreText: PropTypes.string,
  readMoreUrl: PropTypes.string,
}

export default HeroVariantTwo
